export default [
    {
        title: "Claim your nft number",
        subtitle: "Claim your unique nft number from the the Ultimate Digits marketplace.",
        state: "claim"
    },
    
    {
        title: "Sign up",
        subtitle: "Sign up to the ultimate digits official marketplace to claim your number.",
        state: "signup"
    },

    {
        title: "Minting your nft",
        subtitle: "Mint your number and start using it as your new identity on various web3 platforms.",
        state: "mint"
    }
]