import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className='lds-dual-ring'/>
  )
}

export default Loader
